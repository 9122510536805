import React, { useState, useEffect } from 'react';
import styles from './Signup.module.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Line from './Line';
import GoogleAuth from './Google_auth';

function Signup() {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');
  const [mobile, setMobile] = useState('');
  const [businessName, setBusinessName] = useState('');
  const [invalid, setInvalid] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [passwordConfirmError, setPasswordConfirmError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [businessNameError, setBusinessNameError] = useState('');

  const clearErrorMessages = () => {
    setInvalid('');
    setEmailError('');
    setPasswordError('');
    setPasswordConfirmError('');
    setMobileError('');
    setBusinessNameError('');
  };

  useEffect(() => {
    validateForm();
  }, [email, password, passwordConfirm, mobile, businessName]);

  const signupEmail = (e) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(!value.includes('@') || !value.includes('.') ? 'Please enter a valid email address' : '');
  };

  const signupPassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    setPasswordError(value.length < 8 ? 'Password must be at least 8 characters long' : '');
  };

  const signupConfirmPassword = (e) => {
    const value = e.target.value;
    setPasswordConfirm(value);
    setPasswordConfirmError(value !== password ? 'Passwords do not match' : '');
  };

  const signupNumber = (e) => {
    const value = e.target.value;
    setMobile(value);
    setMobileError(value.length < 10 || !/^\d+\$/.test(value) ? 'Mobile number must be at least 10 digits long and numeric' : '');
  };

  const signupBusiness = (e) => {
    const value = e.target.value;
    setBusinessName(value);
    setBusinessNameError(value.trim().length === 0 ? 'Business name is required' : '');
  };

  const validateForm = () => {
    const isEmailValid = email.includes('@') && email.includes('.');
    const isPasswordValid = password.length >= 8;
    const isPasswordConfirmValid = password === passwordConfirm;
    const isMobileValid = mobile.length >= 10 && /^\d+\$/.test(mobile);
    const isBusinessNameValid = businessName.trim().length > 0;

    const formValid = isEmailValid && isPasswordValid && isPasswordConfirmValid && isMobileValid && isBusinessNameValid;

    console.log('Form valid:', formValid);
    setIsButtonDisabled(!formValid);
  };

  const getErrorMessage = () => {
    if (passwordConfirmError) return passwordConfirmError;
    if (emailError) return emailError;
    if (businessNameError) return businessNameError;
    if (mobileError) return mobileError;
    if (passwordError) return passwordError;
    return '';
  };

  const signupFun = async (e) => {
    e.preventDefault();
    console.log('Signup button clicked');
    console.log([businessName, email, mobile, password, passwordConfirm]);

    // Check for validation errors
    if (emailError || passwordError || passwordConfirmError || mobileError || businessNameError) {
      setInvalid('Please correct the errors before signing up.');
      return;
    }

    let signinStat;
    try {
      await axios.post('https://api.hapzea.com/api/v1/user/signup', {
        businessName,
        email,
        mobile,
        password,
        passwordConfirm
      })
        .then((response) => {
          console.log(response.data);
          signinStat = response.data.token;
        })
        .catch((error) => {
          console.error(error.response.data.message);
          setInvalid(error.response.data.message);
          console.log(invalid);
        });
    } catch (error) {
      console.error('Signup error:', error.response?.data?.message || error.message);
      setInvalid(error.response?.data?.message || 'An error occurred during signup');
    }

    if (signinStat) {
      try {
        await axios.post('https://api.hapzea.com/api/v1/user/login', {
          email,
          password
        }, { withCredentials: true }).then(res => {
          console.log(res.data);
          navigate('/services');
        });
      } catch (error) {
        console.error('Login error:', error.response?.data?.message || error.message);
      }
    }
  };

  return (
    <div className={styles.SignupBoxBoundry}>
      <div className={styles.SignupBox}>
        <div className={styles.Signuptext}>
          <p>Sign Up</p>
        </div>
        {getErrorMessage() && <p className={styles.errorMessage}><span>{getErrorMessage()}</span></p>}

        {invalid && (
          <div>
            <p className={styles.invalidStatus}>{invalid}</p>
          </div>
        )}

        <div className={styles.input_group}>
          <label>
            <p>Email</p>
          </label>
          <input
            type='text'
            placeholder=''
            className={styles.input_field}
            onChange={signupEmail}
            onBlur={clearErrorMessages}
          />
        </div>

        <div className={styles.mob_business}>
          <div>
            <label>
              <p>Business Name</p>
            </label>
            <input
              type='text'
              placeholder=''
              className={styles.input_field}
              onChange={signupBusiness}
              onBlur={clearErrorMessages}
            />
          </div>
          <div className={` ${styles.mobile_input}`}>
            <label>
              <p>Mobile Number</p>
            </label>
            <input
              type='text'
              placeholder=''
              className={styles.input_field}
              onChange={signupNumber}
              onBlur={clearErrorMessages}
            />
          </div>
        </div>

        <div className={styles.mob_business}>
          <div className={styles.input_group}>
            <label>
              <p>Password</p>
            </label>
            <input
              type='password'
              placeholder=''
              className={styles.input_field}
              onChange={signupPassword}
              onBlur={clearErrorMessages}
            />
          </div>

          <div className={styles.input_group}>
            <label>
              <p>Confirm Password</p>
            </label>
            <input
              type='password'
              placeholder=''
              className={styles.input_field}
              onChange={signupConfirmPassword}
              onBlur={clearErrorMessages}
            />
          </div>
        </div>

        <button className={styles.Signup_button} onClick={signupFun}>Sign Up</button>

        <div className={styles.signupOption}>
          <Line content='or signup with' />
        </div>
        <div>
          <GoogleAuth />
        </div>
        <div className={styles.newuser}>
          <p>Already a User?</p>
          <Link to='/login'>Login</Link>
        </div>
      </div>
    </div>
  );
}

export default Signup;