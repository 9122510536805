import React, { useRef, useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import Webcam from 'react-webcam';
import './SelfiePage.css';
import { FaCamera, FaRetweet, FaArrowRight } from 'react-icons/fa';
import { FaEnvelope } from 'react-icons/fa'; // Added FaEnvelope icon for email representation

function SelfiePage() {
  const navigate = useNavigate();
  const location = useLocation();
  const { name, email , whatsapp } = location.state || {}; // Replaced 'phone' with 'email'
  const webcamRef = useRef(null);
  const [imageSrc, setImageSrc] = useState('');
  const [isCapturing, setIsCapturing] = useState(false);
  const [error, setError] = useState('');

  const videoConstraints = {
    width: 300,
    height: 420,
    facingMode: "user",
  };

  const capture = useCallback(() => {
    if (webcamRef.current) {
      try {
        setIsCapturing(true);
        const capturedImage = webcamRef.current.getScreenshot();
        if (capturedImage) {
          setImageSrc(capturedImage);
          setError('');
        } else {
          throw new Error('Failed to capture image. Please try again.');
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setIsCapturing(false);
      }
    }
  }, [webcamRef]);

  const retake = () => {
    setImageSrc('');
    setError('');
  };

  const handleSubmit = () => {
    if (imageSrc && name  && email && whatsapp) { // Replaced 'phone' with 'email'
      navigate('../confirm', { state: { imageSrc, name, email , whatsapp } }); // Replaced 'phone' with 'email'
    } else {
      setError('Missing information. Please try again.');
    }
  };

  return (
    <div className="selfie-container">
      <div className="selfie-card">
        <h1 className="selfie-title">Capture Your Selfie</h1>
        <p className="selfie-instruction">Ensure good lighting and hold the camera steady for the best results.</p>
        {!imageSrc ? (
          <div className="webcam-section">
            <Webcam
              audio={false}
              height={420}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              width={300}
              videoConstraints={videoConstraints}
              mirrored={true}
              className="webcam-feed"
              onUserMediaError={(error) => {
                console.error("Webcam error:", error);
                setError("Cannot access camera. Please grant camera permissions and refresh the page.");
              }}
              onUserMedia={() => {
                console.log("Webcam access granted");
                setError('');
              }}
            />

            <button
              className="capture-button"
              onClick={capture}
              aria-label="Capture Selfie"
              disabled={isCapturing}
            >
              <FaCamera />
            </button>
            {isCapturing && (
              <div className="loading-overlay">
                <div className="spinner"></div>
              </div>
            )}
          </div>
        ) : (
          <div className="captured-section">
            <img src={imageSrc} alt="Captured Selfie" className="captured-img" />
            <div className="action-buttons">
              <button
                className="button retake-button"
                onClick={retake}
                aria-label="Retake Selfie"
              >
                <FaRetweet /> Retake
              </button>
              <button
                className="button submit-button"
                onClick={handleSubmit}
                aria-label="Submit Selfie"
              >
                Next <FaArrowRight />
              </button>
            </div>
          </div>
        )}
        {error && <p className="error-message" role="alert">{error}</p>}
      </div>
    </div>
  );
}

export default SelfiePage;
