import React, { useState, useEffect } from 'react';
import { NavLink, Outlet, useNavigate, useLocation, useParams } from 'react-router-dom';
import styles from './AlbumPage.module.css';
import { useSelector, useDispatch } from 'react-redux';
import { UserActions } from '../Store/userSlice';
import axios from 'axios';

function AlbumPage() {
    const { id, businessName, EventName } = useParams();
    const fetchedFiles = useSelector(state => state.user.Album);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [filefetch, setFilefetch] = useState([]);
    const [topperson, setTopperson] = useState({ Groom: '', Bride: '', client: '' });
    const [backgroundImage, setBackgroundImage] = useState('');
    const [backgroundImageMob, setBackgroundImageMob] = useState('');
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    const fetchData = async (id) => {
        try {
            const response = await axios.get('https://api.hapzea.com/api/v1/user/getpublicfiles', {
                params: { _id: id },
                withCredentials: true,
            });
            const { Bride, Groom, ClientName } = response.data.user;
            setTopperson({ Groom, Bride, client: ClientName });

            const { album, photo } = response.data.data;
            setFilefetch(album);
            dispatch(UserActions.firstfiles([album, photo]));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchLatestImage = async (id) => {
        try {
            await new Promise(resolve => setTimeout(resolve, 1000));

            const [coverPhotoResponse, coverPhotoMobResponse] = await Promise.all([
                axios.get(`https://api.hapzea.com/api/v1/user/getCoverPhoto?_id=${id}`, {
                    responseType: 'blob',
                    withCredentials: true,
                }),
                axios.get(`https://api.hapzea.com/api/v1/user/getCoverPhotoMob?_id=${id}`, {
                    responseType: 'blob',
                    withCredentials: true,
                }),
            ]);

            const coverPhotoUrl = URL.createObjectURL(coverPhotoResponse.data);
            const coverPhotoMobUrl = URL.createObjectURL(coverPhotoMobResponse.data);

            console.log(coverPhotoUrl);
            console.log(coverPhotoMobUrl);

            setBackgroundImage(coverPhotoUrl);
            setBackgroundImageMob(coverPhotoMobUrl);

        } catch (error) {
            console.error('Error fetching photos:', error);
        }
    };

    useEffect(() => {
        fetchData(id);
        fetchLatestImage(id);

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [id]);

    return (
        <div className={styles.containerAlbumOuter}>
            <div
                className={styles.container}
                style={{
                    backgroundImage: `url(${isMobile ? backgroundImageMob : backgroundImage})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'bottom'
                }}
            />
            <div className={styles.content}>
                <div className={styles.TitlesAlbum}>
                    {topperson.Groom && topperson.Bride ? (
                        <h2>{topperson.Groom} and {topperson.Bride}</h2>
                    ) : (
                        <h2>{topperson.client}</h2>
                    )}
                </div>
                <div className={styles.AlbumGalleryOn}>
                    <div className={styles.AlbumGalleryOnInside}>
                        <div className={styles.AlbumGalleryLine}></div>
                        <div className={styles.AlbumGalleryTab}>
                            <ul>
                                {filefetch.map((folder, index) => (
                                    <li key={index}>
                                        <NavLink
                                            to={`/album/${businessName}/${EventName}/${id}/${folder}`}
                                            className={({ isActive }) =>
                                                isActive ? styles.activeNavLink : undefined
                                            }
                                        >
                                            {folder}
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AlbumPage;
