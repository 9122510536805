import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './FormPage.css';
import { FaUser, FaEnvelope, FaPhone, FaArrowRight } from 'react-icons/fa';

function FormPage() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [whatsapp, setWhatsapp] = useState('');
  const [emailError, setEmailError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    // Basic email validation
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    if (!emailRegex.test(email)) {
      setEmailError('Please enter a valid email address.');
      return; // Prevent form submission if email is invalid
    } else {
      setEmailError('');
    }

    // Proceed to the next page with name, email, and WhatsApp number
    navigate('selfie', { state: { name, email, whatsapp } });
  };

  // Email validation condition to disable the "Next" button
  const isEmailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email);

  return (
    <div className="form-page">
      <div className="form-container">
        <h1 className="form-title">Welcome!</h1>
        <form onSubmit={handleSubmit} className="user-form">
          <div className="input-group">
            <FaUser className="input-icon" />
            <input
              type="text"
              placeholder="Enter Your Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              aria-label="Name"
            />
          </div>

          <div className="input-group">
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              placeholder="Enter Your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              aria-label="Email Address"
            />
          </div>

          <div className="input-group">
            <FaPhone className="input-icon" />
            <input
              type="tel"
              placeholder="Enter Your WhatsApp Number"
              value={whatsapp}
              onChange={(e) => setWhatsapp(e.target.value)}
              required
              aria-label="WhatsApp Number"
            />
          </div>

          {emailError && <p className="error-message">{emailError}</p>} {/* Display error message */}

          <button
            type="submit"
            className="submit-button"
            aria-label="Proceed to Selfie"
            disabled={!isEmailValid} // Disable button if email is invalid
          >
            <FaArrowRight className="button-icon" />
            Next
          </button>
        </form>
      </div>
    </div>
  );
}

export default FormPage;
