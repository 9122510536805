import React, { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import './ConfirmPage.css';
import { FaEnvelope } from 'react-icons/fa';
import axios from 'axios';

function ConfirmPage() {
  const location = useLocation();
  const { id } = useParams();
  const { imageSrc, name, email , whatsapp} = location.state || {};

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isRegistered, setIsRegistered] = useState(false);

  // console.log('Received data:', { imageSrc, name, email });

  if (!imageSrc || !name || !email || !whatsapp) {
    return (
      <div className="confirm-container">
        <p className="error-message">Missing data, please go back and try again.</p>
      </div>
    );
  }

  const base64ToBlob = (base64, mime) => {
    try {
      const byteCharacters = atob(base64.split(',')[1]);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: mime });
    } catch (error) {
      console.error('Error converting Base64 to Blob:', error);
      return null;
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrorMessage('');
    setSuccessMessage('');
  
   
    // Trim the name before proceeding with any operations
    const trimmedName = name.trim();
  
    try {
      const mimeMatch = imageSrc.match(/data:(image\/.+);base64,/);
      if (!mimeMatch) throw new Error('Invalid image format.');
      const mimeType = mimeMatch[1];
  
      const imageBlob = base64ToBlob(imageSrc, mimeType);
      if (!imageBlob) throw new Error('Failed to convert image.');
  
      const guestImage = new File([imageBlob], `guest-${Date.now()}.${mimeType.split('/')[1]}`, {
        type: mimeType,
      });
   
      const formData = new FormData();
      formData.append('guestImage', guestImage);
      formData.append('name', trimmedName); // Use trimmed name here
      formData.append('email', email);
      formData.append('eventId', id);
      formData.append('mobile', whatsapp);
  
      const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.hapzea.com/api/v1';
  
      const response = await axios.post(
        `${API_BASE_URL}/user/register-guest?eventId=${encodeURIComponent(id)}`,
        formData,
        { withCredentials: true }
      );
  
      if (response.status === 200) {
        setSuccessMessage('Registration successful!');
        setIsRegistered(true); // Set isRegistered to true on success
      } else if (response.status === 400) {
        console.log('RRRRRRRRRRR');
        setErrorMessage(response.data.message);
      } else {
        setErrorMessage('Registration failed. Please try again.');
      }
    } catch (error) {
      console.error('Error registering guest:', error);
  
      let message = 'An unexpected error occurred.';
      if (error.response) {
        if (error.response.data && typeof error.response.data.message === 'string') {
          message = error.response.data.message;
        } else if (error.response.data && error.response.data.error?.storageErrors) {
          const storageErrors = error.response.data.error.storageErrors;
          message = Array.isArray(storageErrors)
            ? storageErrors.join(', ')
            : Object.values(storageErrors).join(', ');
        } else if (error.response.status && error.response.statusText) {
          message = `Error ${error.response.status}: ${error.response.statusText}`;
        }
      } else if (error.message) {
        message = error.message;
      }
  
      setErrorMessage(message);
    } finally {
      setIsSubmitting(false);
    }
  };
  

  return (
    <div className="confirm-container">
      <div className="image-wrapper">
        <img src={imageSrc} alt="Selfie Preview" className="selfie-preview" />
      </div>
      <div className="confirm-details">
        <h2 className="name">{name}</h2>
        <p className="email">
          <FaEnvelope className="email-icon" /> {email}
        </p>
      </div>
      {errorMessage && <p className="error-message">{errorMessage}</p>}
      {successMessage && <p className="success-message">{successMessage}</p>}
      <button
        className={`submit-button ${isSubmitting || isRegistered ? 'disabled' : ''}`}
        onClick={handleSubmit}
        disabled={isSubmitting || isRegistered}
        aria-label="Submit your details"
      >
        {isSubmitting ? 'Submitting...' : isRegistered ? 'Registered' : 'Submit'}
      </button>
    </div>
  );
}

export default ConfirmPage;
