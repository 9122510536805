// ImageUpload.js

import React, { useRef, useState, useEffect } from 'react';
import { GoUpload } from "react-icons/go";
import './ImageUpload.css';
import { IoIosCloudUpload } from 'react-icons/io';
import { FaCheckCircle, FaTimesCircle, FaSpinner } from 'react-icons/fa'; // Added FaSpinner for uploading state
import uploadIcon from '../../img/upload.svg';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { io } from 'socket.io-client';
import pLimit from 'p-limit';

export default function ImageUpload_face() {
  const fileInputRef = useRef(null);
  const [uploadProgress, setUploadProgress] = useState({}); // Tracks progress of each image
  const [failedUploads, setFailedUploads] = useState([]); // Tracks failed uploads
  const [uploadedCount, setUploadedCount] = useState(0); // Tracks successful uploads
  const [selectedCount, setSelectedCount] = useState(0); // Total selected images
  const [showProgressBar, setShowProgressBar] = useState(true); // Controls visibility of progress bar
  const { id: eventId } = useParams();

  // Initialize Socket.IO client
  const socket = useRef(null);
  useEffect(() => {
    socket.current = io(process.env.REACT_APP_API_BASE_URL || 'https://api.hapzea.com');

    // Listen for progress updates
    socket.current.on('uploadProgress', (data) => {
      console.log('Upload Progress:', data.progress);
      // Optionally handle real-time progress updates here
    });

    // Listen for upload completion
    socket.current.on('uploadComplete', (data) => {
      console.log('Upload Complete:', data.message);
      // alert('All images have been processed successfully.');
    });

    // Listen for upload errors
    socket.current.on('uploadError', (data) => {
      console.error('Upload Error:', data.message);
      // alert(`Error: ${data.message}`);
    });

    // Cleanup on unmount
    return () => {
      socket.current.disconnect();
    };
  }, []);

  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.hapzea.com';

  /**
   * Handle file selection and initiate upload process
   */
  const handleFileChange = async (event) => {
    const files = Array.from(event.target.files);
    console.log("Selected files:", files);
    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
          console.log(`File ${file.name} is readable`);
      };
      reader.onerror = () => {
          console.error(`File ${file.name} is not readable`);
      };
      reader.readAsArrayBuffer(file);
  });
    if (files.length === 0) return;
    setSelectedCount(files.length);
    setUploadProgress({});
    setFailedUploads([]);
    setUploadedCount(0); // Reset uploaded count on new upload
    setShowProgressBar(true); // Show progress bar on new upload
    try {
      // Step 1: Request pre-signed URLs from the backend
      const presignedResponse = await axios.post(
        `${API_BASE_URL}/api/v1/user/get-signed-urls`,
        {
          files: files.map(file => ({
            filename: file.name,
            fileType: file.type,
          })),
          eventId: eventId,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Adjust based on your auth implementation
          },
        }
      );

      const { signedUrls } = presignedResponse.data;

      if (!signedUrls || signedUrls.length === 0) {
        throw new Error('No signed URLs returned from the server.');
      }

      // Step 2: Upload each file to S3 using the pre-signed URL
      const limit = pLimit(5); // Limit concurrency to 5

      const uploadPromises = signedUrls.map((signedUrlObj, index) => {
        const file = files[index];
        return limit(() => uploadFile(file, signedUrlObj.url, signedUrlObj.key));
      });

      await Promise.all(uploadPromises);

      // Step 3: Notify backend of the successful uploads
      await notifyBackendOfUploads(signedUrls.map(urlObj => urlObj.key));

      // Optionally, you can fetch the processed results here or rely on real-time updates via Socket.IO
    } catch (error) {
      console.error('Error during the upload process:', error);
      // alert('An error occurred during the upload process. Please try again.');
    }
  };

  /**
   * Function to upload a single file using its pre-signed URL
   */
  const uploadFile = async (file, url, key) => {
    try {
      console.log(`[${new Date().toISOString()}] Initiating upload for ${file.name}`);
      
      // Initialize progress for this file to 0%
      setUploadProgress(prev => ({
        ...prev,
        [key]: 0,
      }));

      await axios.put(url, file, {
        headers: {
          'Content-Type': file.type,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          setUploadProgress(prev => ({
            ...prev,
            [key]: percentCompleted,
          }));
        },
      });

      console.log(`[${new Date().toISOString()}] Upload successful for ${file.name}`);

      // Increment the count of successfully uploaded images
      setUploadedCount(prev => prev + 1);

      // Set progress to 100%
      setUploadProgress(prev => ({
        ...prev,
        [key]: 100,
      }));

      // Remove the file from the upload queue after a 5-second delay
      setTimeout(() => {
        setUploadProgress(prev => {
          const updatedProgress = { ...prev };
          delete updatedProgress[key];
          return updatedProgress;
        });
        setSelectedCount(prev => prev - 1); // Decrement selected count
      }, 5000); // 5000ms = 5 seconds delay

    } catch (error) {
      console.error(`[${new Date().toISOString()}] Error uploading ${file.name}:`, error);
      setFailedUploads(prev => [...prev, file.name]);
      setUploadProgress(prev => ({
        ...prev,
        [key]: -1, // -1 indicates an error
      }));
      setSelectedCount(prev => prev - 1); // Decrement selected count even on failure
    }
  };

  /**
   * Function to notify backend of completed uploads
   */
  const notifyBackendOfUploads = async (s3Keys) => {
    try {
      // Send a POST request to the backend to process the uploaded images
      const processResponse = await axios.post(
        `${API_BASE_URL}/api/v1/user/process-uploaded-images`,
        {
          s3Keys: s3Keys,
          eventId: eventId,
          socketId: socket.current.id,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Adjust based on your auth implementation
          },
        }
      ); 

      console.log('Backend processing initiated:', processResponse.data);
    } catch (error) {
      console.error('Error notifying backend of uploads:', error);
      // alert('An error occurred while notifying the backend. Please try again.');
    }
  };

  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  // Calculate the number of images currently uploading
  const currentUploadingCount = selectedCount;

  // Determine if all uploads are complete
  const allUploadsComplete = selectedCount === 0 && uploadedCount > 0;

  /**
   * Prevent users from leaving the page during uploads
   */
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault();
      e.returnValue = ''; // Required for Chrome to display the confirmation dialog
    };

    if (selectedCount > 0) {
      window.addEventListener('beforeunload', handleBeforeUnload);
    } else {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    }

    // Cleanup on unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [selectedCount]);

  return (
    <div className="content-section">
      {/* Enhanced Progress Bar */}
      {(selectedCount > 0 || uploadedCount > 0) && showProgressBar && (
        <div className="upload-progress-face">
          {/* Header showing the total number of images uploading or uploaded */}
          <div className="upload-progress-face-header">
            {selectedCount > 0 ? (
              <span>Uploading {currentUploadingCount} {currentUploadingCount === 1 ? 'image' : 'images'}</span>
            ) : (
              <span>{uploadedCount} {uploadedCount === 1 ? 'image' : 'images'} uploaded</span>
            )}
          </div>

          {/* List of current uploads */}
          {Object.keys(uploadProgress).map((key) => (
            <div key={key} className="progress-item-face">
              {/* Icon based on status */}
              <div className="progress-icon-face">
                {uploadProgress[key] === 100 ? (
                  <FaCheckCircle className="success" size={20} />
                ) : uploadProgress[key] === -1 ? (
                  <FaTimesCircle className="error" size={20} />
                ) : (
                  <FaSpinner className="uploading spinner" size={20} />
                )}
              </div>
              
              {/* Filename and Progress */}
              <div style={{ flex: 1 }}>
                <span className="filename">{key.split('/').pop()}</span>
                <div className="progress-bar-face">
                  <div
                    className="progress-fill-face"
                    style={{
                      width: uploadProgress[key] > 0 ? `${Math.min(uploadProgress[key], 100)}%` : '0%',
                      backgroundColor: uploadProgress[key] === -1 ? '#f44336' : '#4caf50',
                    }}
                  ></div>
                </div>
              </div>

              {/* percentage-face */}
              <div className="percentage-face">
                {uploadProgress[key] === -1
                  ? 'Error'
                  : uploadProgress[key] > 0
                  ? `${Math.min(uploadProgress[key], 100)}%`
                  : '0%'}
              </div>
            </div>
          ))}

          {/* Display uploaded count message if all uploads are complete */}
          {allUploadsComplete && (
            <div className="upload-summary">
              <span>{uploadedCount} {uploadedCount === 1 ? 'image' : 'images'} uploaded successfully.</span>
              <button className="close-btn" onClick={() => setShowProgressBar(false)}>×</button>
            </div>
          )}

          {/* Failed uploads section within the progress bar */}
          {failedUploads.length > 0 && (
            <div className="failed-uploads">
              <h3>Failed Uploads:</h3>
              <ul>
                {failedUploads.map((fileName, index) => (
                  <li key={index}>{fileName}</li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}

      {/* Upload Box */}
      <div className="upload-box">
  {/* <img className="uploadIcon" src={uploadIcon} alt="Upload Icon" /> */}
      <GoUpload className="uploadIcon"/>
  <p className="upload-text">Drag and Drop Images here or Click to Upload</p>

  <button className="upload-btn" onClick={handleUploadClick} aria-label="Upload Images">
    <IoIosCloudUpload className="upload-icon" />
    Upload
  </button>
  <input
    type="file"
    ref={fileInputRef}
    style={{ display: 'none' }}
    multiple
    accept="image/*"
    onChange={handleFileChange}
  />
</div>


      {/* Failed uploads section below the upload box */}
      {failedUploads.length > 0 && (
        <div className="failed-uploads-section">
          <h3>Failed Uploads:</h3>
          <ul>
            {failedUploads.map((fileName, index) => (
              <li key={index}>{fileName}</li>
            ))}
          </ul>
        </div>
      )}

      {/* Upload Overlay */}
      {selectedCount > 0 && (
        <div className="upload-overlay">
          <div className="upload-overlay-content">
            <FaSpinner className="uploading spinner" size={50} />
            <p>Please stay on the page while your images are uploading...</p>
          </div>
        </div>
      )}
    </div>
  );
}




